import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { compose, pure, withProps } from 'recompose'
import Img from 'gatsby-image'

const Container = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-wrap: wrap;

  @media screen and (min-width: 36em) {
    margin: 0 1em;
  }
`

const Item = styled.li`
  width: 100%;

  * + * {
    margin-top: 1em;
  }

  @media screen and (min-width: 36em) {
    width: 20%;
    padding: 1em;

    * + * {
      margin: 0;
    }
  }
`

const ImageGallery = ({ images }) => (
  <Container>
    {images.map(image => (
      <Item key={image.fluid.src}>
        <Img {...image} />
      </Item>
    ))}
  </Container>
)

ImageGallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      fluid: PropTypes.object.isRequired,
      alt: PropTypes.string.isRequired,
    })
  ).isRequired,
}

const addCustomSizesToImage = sizes => image => ({
  ...image,
  fluid: {
    ...image.fluid,
    sizes: `${sizes}, ${image.fluid.sizes}`,
  },
})

const withCustomImageSizes = sizes =>
  withProps(({ images }) => ({
    images: images.map(addCustomSizesToImage(sizes)),
  }))

export default compose(
  pure,
  withCustomImageSizes(`
    (min-width: 36em) calc(((100vw - 2em) * .2) - 2em),
    100vw
  `)
)(ImageGallery)
