import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import { mapProps } from 'recompose'
import DefaultTemplate from '../components/DefaultTemplate'
import ImageGallery from '../components/ImageGallery'
import { BreadcrumbSchema, ServiceSchema } from '../components/JsonLd'
import PageMeta from '../components/metadata/PageMeta'
import ReviewsList from '../components/ReviewsList'
import SecondaryNavigation, { mapPagesToItems } from '../components/SecondaryNavigation'
import { useServicePages } from '../queries'

const ServiceTemplate = ({
  reviews,
  galleryThumbs,
  serviceSchema,
  breadcrumbs,
  page,
  nextPage,
  prevPage,
  ...props
}: any) => {
  const servicesPages = useServicePages()
  const navigationItems = mapPagesToItems(servicesPages)

  return (
    <>
      <PageMeta page={page} />

      <SecondaryNavigation items={navigationItems} />

      <PaginationNavigation next={nextPage} prev={prevPage} />

      <Img {...props.heroImage} />

      <MDXRenderer>{page.markdown.body}</MDXRenderer>

      <ImageGallery images={galleryThumbs} />

      <ReviewsList reviews={reviews} />

      <ServiceSchema {...serviceSchema} />

      <BreadcrumbSchema items={breadcrumbs} />
    </>
  )
}

export const pageQuery = graphql`
  query ServicePageByPath($path: String!, $slug: String!) {
    site {
      ...SiteMeta
    }

    page: page(path: { eq: $path }) {
      ...PageContent
    }

    service(slug: { eq: $slug }) {
      name

      markdown {
        frontmatter {
          name
          alternateName
          gallery {
            alt
            file {
              thumbs: childImageSharp {
                fluid(maxWidth: 300, maxHeight: 300, cropFocus: ATTENTION) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    reviews: allReviewsYaml(
      filter: { service: { eq: $slug } }
      sort: { fields: [date], order: DESC }
      limit: 10
    ) {
      edges {
        node {
          ...ReviewContent
          ...ReviewMeta
        }
      }
    }
  }
`

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const mapQueryProps = mapProps(({ data, pageContext }) => ({
  ...DefaultTemplate.selectProps({ data }),

  prevPage: pageContext.prev,
  nextPage: pageContext.next,

  breadcrumbs: [
    {
      path: '/services/',
      name: 'Services',
    },
    {
      path: data.page.path,
      name: data.service.name,
    },
  ],

  serviceSchema: {
    ...data.site.siteMetadata,
    path: data.page.path,
    description: data.page.description,
    name: data.service.markdown.frontmatter.name,
    alternateName: data.service.markdown.frontmatter.alternateName,
    image: data.service.markdown.frontmatter.image
      ? data.service.markdown.frontmatter.image.file.meta.fluid.src
      : null,
  },

  galleryThumbs: data.service.markdown.frontmatter.gallery
    ? // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      data.service.markdown.frontmatter.gallery.map(({ file, alt }) => ({
        ...file.thumbs,
        alt,
      }))
    : [],

  reviews: ReviewsList.selectReviewsProp(data.reviews, data.site.siteMetadata),
}))

export default mapQueryProps(ServiceTemplate)

type ContextPageNode = { slug: string; name: string; page: { path: string } }

const PaginationNavigation = (props: { prev?: ContextPageNode; next?: ContextPageNode }) => {
  return (
    <ul>
      {props.prev && (
        <li>
          <Link to={props.prev.page.path}>Previous ({props.prev.name})</Link>
        </li>
      )}
      {props.next && (
        <li>
          <Link to={props.next.page.path}>Next ({props.next.name})</Link>
        </li>
      )}
    </ul>
  )
}
